.app-with-light-sidebar {
	& .app-sidebar {
		background: $white;
		
		& .menu {
			& .menu-header {
				color: $gray-500;
			}
			& .menu-divider {
				background: $gray-300;
			}
			& .menu-search {
				& .form-control {
					background: none;
					border: none;
					padding-left: 0;
					padding-right: 0;
					border-bottom: 1px solid $border-color;
					color: $dark;
					border-radius: 0;
				
					&:focus {
						border-color: darken($border-color, 15%);
						box-shadow: none;
					}
				}
			}
			& .menu-item {
				& > .menu-link {
					color: $gray-800;
					font-weight: $font-weight-bold;
					
					& .menu-icon {
						color: $gray-500;
					}
					& .menu-badge {
						background: $gray-100;
						color: $gray-600;
						font-weight: $font-weight-bolder;
					}
					&:hover,
					&:focus {
						color: $gray-400;
					}
				}
				&.active {
					& > .menu-link {
						color: var(--app-theme-color);
						
						& .menu-icon,
						& .menu-caret {
							color: var(--app-theme-color);
						}
					}
				}
				
				& .menu-submenu {
					background: $gray-50;
					
					& > .menu-item {
						& > .menu-link {
							color: $gray-400;
							font-weight: $font-weight-bold;
					
							&:hover,
							&:focus {
								color: $dark;
								background: none;
							}
							&:after {
								background: $gray-300;
							}
						}
						&:before {
							background: $gray-300;
						}
						&:after {
							border-width: 2px;
							border-color: $gray-300;
							background: $white;
						}
						&.active {
							& > .menu-link {
								color: $gray-900;
							}
							&:after {
								border-color: $primary;
							}
						}
						&.has-sub {
							& > .menu-link {
								&:before {
									background: $gray-300;
								}
							}
						}
					}
				}
			}
			& .app-sidebar-minify-btn {
				background: $gray-100;
				color: $gray-500;
			
				& i {
					color: $gray-500;
				}
			
				&:hover,
				&:focus {
					background: $gray-200;
					color: $gray-700;
					
					& i {
						color: $gray-700;
					}
				}
			}
		}
	}
	& .app-sidebar-bg {
		background: $white;
	}
	& .app-sidebar-float-submenu-container {
		background: $white;
		box-shadow: $box-shadow-sm;
		
		& .app-sidebar-float-submenu-arrow,
		& .app-sidebar-float-submenu-line {
			background: $gray-100;
		}
		& .app-sidebar-float-submenu {
			& .menu-item {
				& > .menu-link {
					color: $gray-600;
					font-weight: $font-weight-bold;
					
					&:hover,
					&:focus {
						color: $gray-900;
					}
					&:after {
						background: $gray-100;
					}
				}
				&:before {
					background: $gray-100;
				}
				&:after {
					background: $gray-100;
				}
				&.active,
				&.expand,
				&.expanding {
					& > .menu-link {
						color: $gray-900;
					}
					&:after {
						background-color: $primary;
					}
				}
			}
		}
	}
	&.app-sidebar-minified {
		& .app-sidebar:not(.app-sidebar-end) {
			& .menu {
				& > .menu-item:not(.active) {
					& > .menu-link {
						&:hover,
						&:focus {
							& .menu-icon {
								color: $gray-700;
							}
						}
					}
				}
			}
		}
	}
}