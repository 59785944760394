@import "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700";
@import "https://cdnjs.cloudflare.com/ajax/libs/nvd3/1.8.1/nv.d3.min.css";
@import "https://unpkg.com/ionicons@4.2.2/dist/css/ionicons.min.css";

@font-face {
    font-family: 'ITCAvantGarde';
    src: local('ITCAvantGarde'), url(/public/ITCAvantGardeGothicStdBook.otf) format('opentype');
}

.saniplast {
    font-family: 'ITCAvantGarde';
}